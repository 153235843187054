//Script ayant un lien avec le scroll

$('.scrollTo a').click(function (scrollTo) {
    scrollTo.preventDefault();
    $('html, body').animate({
        scrollTop: $(".scrollTo").offset().top
    }, 400);
});

$("#sous_theme").chained("#theme");

// $('select#filterActualites').select2();



$("#acceptCookie").click(function () {
    Cookies.set('cookie_notice_accepted', 'true', {
        expires: 1
    });
    document.location.reload(true);
});


if (!Cookies.get('alerte_Popup')) {
    setTimeout(function () {
        $('#alertePopUp').css({ display: "block" });
    });
}else{
    var cookieMd5 = Cookies.get('alerte_Popup');
    var cookie_content = $('#alertePopUp').html();
    var MD5 = new Hashes.MD5().hex(cookie_content);
    if( cookieMd5 !== MD5 ){
        $('#alertePopUp').css({ display: "block" });
    }
}

$("#alertePopUp button").click(function () {
    if ( Cookies.get('cookie_notice_accepted') === 'true') {

        var cookie_content = $(this).closest('#alertePopUp').html();
        var MD5 = new Hashes.MD5().hex(cookie_content)
        Cookies.set('alerte_Popup', MD5, {
            expires: 1
        });

    }
    $('#alertePopUp').css({ display: "none" });
});


if (!Cookies.get('alerte_Bandeau')) {
    setTimeout(function () {
        $('#alerteBandeau').css({ display: "block" });
    });
}else{
    var cookieMd5 = Cookies.get('alerte_Bandeau');
    var cookie_content = $('#alerteBandeau').html();
    var MD5 = new Hashes.MD5().hex(cookie_content);
    if( cookieMd5 !== MD5 ){
        $('#alerteBandeau').css({ display: "block" });
    }
}

$("#alerteBandeau button").click(function () {
    if ( Cookies.get('cookie_notice_accepted') === 'true') {

        var cookie_content = $(this).closest('#alerteBandeau').html();
        var MD5 = new Hashes.MD5().hex(cookie_content)
        Cookies.set('alerte_Bandeau', MD5, {
            expires: 1
        });

    }
    $('#alerteBandeau').css({ display: "none" });
});


$('a.sectionAnchor').click(function () {
    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);

    return false;
});

$(document).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll < 355) {
        var topCalc = '400' - scroll;

    }
});

$('#goToTop').click(function (e) {
    e.preventDefault();
    $('html,body').animate({
        scrollTop: 0
    }, 700);
    return false;
});

if($(window).width() < 768){
    $("#btnMenuServices").click(function (e){
        $("ul.left").removeClass('in');
    });
    $("#btnMenuVille").click(function (e){
        $("ul.left").removeClass('in');
    });
    $("#btnMenuActus").click(function (e){
        $("ul.left").removeClass('in');
    });
    $(".big_menu_return").click(function (e) {
        e.preventDefault();

        $(".bigmenu_services.active, .bigmenu_ville.active").css({
            'transform': 'translateY(-100%)',
            'opacity': '0',
            'transition': 'all .25s linear'
        });

        $(".bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
        $(".bigmenu_overlay").remove();
        $('ul.left.collapse').addClass('in');
        $('html,body').animate({
            scrollTop: 0
        }, 700);
    });

    $(".bigmenu_ville .content ul li").click(function (e){
        if($(this).children('a').hasClass('disabled_item')){
            $(this).children('a').removeAttr('href');
            $( this ).children('ul').fadeIn( 200 );
        }
    });

} else {
    $(".bigmenu_ville .content>ul>li").click(function (e) {
        if ($(this).children("ul").length) {
            $('.bigmenu_ville .content>ul>li>ul').fadeOut(0);
            $(this).children('a').removeAttr('href');
            $( this ).children('ul').fadeIn( 0 );
            var count_li = $(this).children("ul").children("li").length;
            var height_list = count_li * 75;
            $( this ).parent('ul').css("height",height_list);
            $( this ).children('ul').css("height",height_list);
        }
    });

    $(".bigmenu_ville .content>ul>li>ul>li").click(function (e) {
        if ($(this).children("ul").length) {
            $('.bigmenu_ville .content>ul>li>ul>li>ul').fadeOut(0);
            $(this).children('a').removeAttr('href');
            $( this ).children('ul').fadeIn( 0 );
        }
    });
    // $( '.bigmenu_ville .content ul li' ).hover(
    //   function() {
    //     $( this ).children('ul').fadeIn( 200 );
    //   }, function() {
    //     $( this ).children('ul').fadeOut( 200 );
    //   }
    // );

    // $( '.bigmenu_ville .content>ul>li' ).hover(
    //     function() {
    //         var count_li = $(this).children("ul").children("li").length;
    //         var height_list = count_li * 90;
    //         $( this ).parent('ul').css("height",height_list);
    //         $( this ).children('ul').css("height",height_list);
    //     }, function() {
    //     }
    // );
}

$("button.navbar-toggle.menu_burger").click(function (e) {
    if($('ul.left.collapse').hasClass('in')){
        $(this).parent().parent().css('position', 'fixed');
    } else{
        // $(this).parent().parent().css('position', 'relative');
        $('html,body').animate({
                scrollTop: 0
        }, 700);
    }
});

$("li.closeBtn.close_mobile").click(function (e) {
    $(this).parent().parent().parent().css('position', 'fixed');
});

if($('form#sib_signup_form_4').length){
    $('p.sib-email-area').css('visibility', 'hidden');
    var nom = "";
    var prenom = "";
    var random_num = 1 + Math.floor(Math.random() * 99);
    $("input.sib-PRENOM-area").bind("change paste keyup", function() {
       prenom = $(this).val(); 
    });
    $("input.sib-NOM-area").bind("change paste keyup", function() {
       nom = $(this).val(); 
       $('input.sib-email-area').val(prenom+'_'+nom+random_num+'@adress.com');
    });
}

window.onresize = function(event) {
   if($(window).width() > 768){
        $("ul.left").removeClass('in');

        $(".bigmenu_ville .content>ul>li").click(function (e) {
            if ($(this).children("ul").length) {
                $('.bigmenu_ville .content>ul>li>ul').fadeOut(0);
                $(this).children('a').removeAttr('href');
                $( this ).children('ul').fadeIn( 0 );
                var count_li = $(this).children("ul").children("li").length;
                var height_list = count_li * 90;
                $( this ).parent('ul').css("height",height_list);
                $( this ).children('ul').css("height",height_list);
            }
        });

        $(".bigmenu_ville .content>ul>li>ul>li").click(function (e) {
            if ($(this).children("ul").length) {
                $('.bigmenu_ville .content>ul>li>ul>li>ul').fadeOut(0);
                $(this).children('a').removeAttr('href');
                $( this ).children('ul').fadeIn( 0 );
            }
        });

        // $( '.bigmenu_ville .content ul li' ).hover(
        //   function() {
        //     $( this ).children('ul').fadeIn( 200 );
        //   }, function() {
        //     $( this ).children('ul').fadeOut( 200 );
        //   }
        // );
        // $( '.bigmenu_ville .content>ul>li' ).hover(
        //     function() {
        //         var count_li = $(this).children("ul").children("li").length;
        //         var height_list = count_li * 90;
        //         $( this ).parent('ul').css("height",height_list);
        //         $( this ).children('ul').css("height",height_list);
        //     }, function() {
        //     }
        // );
   } else {
        $("#btnMenuServices").click(function (e){
            $("ul.left").removeClass('in');
        });
        $("#btnMenuVille").click(function (e){
            $("ul.left").removeClass('in');
        });
        $("#btnMenuActus").click(function (e){
            $("ul.left").removeClass('in');
        });
        $(".big_menu_return").click(function (e) {
            e.preventDefault();

            $(".active").css({
                'transform': 'translateY(-100%)',
                'opacity': '0',
                'transition': 'all .25s linear'
            });

            $(".bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
            $(".bigmenu_overlay").remove();
            $('ul.left.collapse').addClass('in');
            $('html,body').animate({
                scrollTop: 0
            }, 700);
        });

        $(".bigmenu_ville .content ul li").click(function (e){
            if($(this).children('a').hasClass('disabled_item')){
                $(this).children('a').removeAttr('href');
                $( this ).children('ul').fadeIn( 200 );
            }
        });
   }
};

$("#date_agenda").datepicker({ dateFormat: 'yy-mm-dd' });

$('#goLittleDown').click(function (e) {
    e.preventDefault();
    $('html,body').animate({
        scrollTop: ($("sidebar").offset().top - 145)
    }, 700);
});

$(".masquer").click(function () {
    var page_content = $(this).parent().find(".content_page_single");
    var side_bar = $(this).parent().find("sidebar");
    page_content.fadeOut( 400 );
    side_bar.fadeOut( 400 );
    $(this).fadeOut( 400 );
    $(this).parent().find(".en_savoir_plus").fadeIn( 800 );
    $('html,body').animate({
        scrollTop: $(this).parent().offset().top
    }, 400);
});

$(".en_savoir_plus").click(function () {
    var page_content = $(this).parent().find(".content_page_single");
    var side_bar = $(this).parent().find("sidebar");
    page_content.fadeIn( 800 );
    side_bar.fadeIn( 600 );
    $(this).fadeOut( 400 );
    $(this).parent().find(".masquer").fadeIn( 800 );
});

// $("#sideBtnActu").click(function (Event) {
//     Event.preventDefault();
//     $(".bigmenu_actus").removeAttr('style');
    
//     $(".activeOnglet").removeClass("activeOnglet");
//     $(".actus").addClass("activeOnglet");
    
//     $(".actus_container").css("display", "block");
//     $(".evenements_container, .facebook_container, .derniers_jours_container ").css("display", "none");
    
//     $(".bigmenu_actus.active, .bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
//     $(".bigmenu_actus").addClass("active");
//     $('html, body').animate({
//         scrollTop: 0
//     }, 400);

//     if (!$('.bigmenu_overlay')[0]) {
//         $(".bigmenu_actus").after("<div class='bigmenu_overlay'></div>");
//     }
// });

// $("#sideBtnAgendas").click(function (Event) {
//     Event.preventDefault();
//     $(".bigmenu_actus").removeAttr('style');
    
//     $(".activeOnglet").removeClass("activeOnglet");
//     $(".agenda").addClass("activeOnglet");
    
//     $(".evenements_container").css("display", "block");

//     $(".actus_container, .facebook_container, .derniers_jours_container ").css("display", "none");
    
//     $(".bigmenu_actus.active, .bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
//     $(".bigmenu_actus").addClass("active");
//     $('html, body').animate({
//         scrollTop: 0
//     }, 400);

//     if (!$('.bigmenu_overlay')[0]) {
//         $(".bigmenu_actus").after("<div class='bigmenu_overlay'></div>");
//     }
// });

// $("#btnMenuActus").click(function (Event) {
//     Event.preventDefault();
//     $(".bigmenu_actus").removeAttr('style');
//     $(".bigmenu_actus.active, .bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
//     $(".bigmenu_actus").addClass("active");
//     $('html, body').animate({
//         scrollTop: 0
//     }, 400);

//     if (!$('.bigmenu_overlay')[0]) {
//         $(".bigmenu_actus").after("<div class='bigmenu_overlay'></div>");
//     }
//     $('.bigmenu_overlay').click(function () {
//         $(".active").css({
//         'transform': 'translateY(-100%)',
//         'opacity': '0',
//         'transition': 'all .25s linear'
//         });

//         $(".bigmenu_actus.active, .bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
//         $(".bigmenu_overlay").remove();
//         $('ul.left.collapse').removeClass('in');
//         });
// });

$("#btnMenuServices").click(function (Event) {
    Event.preventDefault();
    $(".bigmenu_services").removeAttr('style');
    $(".bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
    $(".bigmenu_services").addClass("active");
    $('html, body').animate({
        scrollTop: 0
    }, 400);

    if (!$('.bigmenu_overlay')[0]) {
        $(".bigmenu_actus").after("<div class='bigmenu_overlay'></div>");
    }
    $('.bigmenu_overlay').click(function () {
        $(".active").css({
        'transform': 'translateY(-100%)',
        'opacity': '0',
        'transition': 'all .25s linear'
        });

        $(".bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
        $(".bigmenu_overlay").remove();
        $('ul.left.collapse').removeClass('in');
        });
});

$("#btnMenuVille").click(function (Event) {
    Event.preventDefault();
    $(".bigmenu_ville").removeAttr('style');
    $(".bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
    $(".bigmenu_ville").addClass("active");
    $('html, body').animate({
        scrollTop: 0
    }, 400);

    if (!$('.bigmenu_overlay')[0]) {
        $(".bigmenu_actus").after("<div class='bigmenu_overlay'></div>");
    }
    $('.bigmenu_overlay').click(function () {
        $(".active").css({
        'transform': 'translateY(-100%)',
        'opacity': '0',
        'transition': 'all .25s linear'
        });

        $(".bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
        $(".bigmenu_overlay").remove();
        $('ul.left.collapse').removeClass('in');
        });
});

$('li.closeBtn').click(function (e) {
    e.preventDefault();
    $('ul.left.collapse').removeClass('in');
});

$(".big_menu_close").click(function (e) {
    e.preventDefault();

    $(".bigmenu_services.active, .bigmenu_ville.active").css({
        'transform': 'translateY(-100%)',
        'opacity': '0',
        'transition': 'all .25s linear'
    });

    $(".bigmenu_services.active, .bigmenu_ville.active").removeClass("active");
    $(".bigmenu_overlay").remove();
    $('ul.left.collapse').removeClass('in');

});

// $(".bigmenu_actus button").click(function () {
//     if ($(this).hasClass('derniers_jours') || $(this).hasClass('facebook') || $(this).hasClass('actus') || $(this).hasClass('agenda')) {
//         $(".bigmenu_actus button.activeOnglet").removeClass("activeOnglet");
//         $(this).addClass("activeOnglet");

//         if ($(this).hasClass('derniers_jours')) {
//             $(".derniers_jours_container").css("display", "block");
//             $(".evenements_container, .actus_container, .facebook_container ").css("display", "none");
//         }
//         if ($(this).hasClass('facebook')) {
//             $(".facebook_container").css("display", "block");
//             $(".evenements_container, .actus_container, .derniers_jours_container ").css("display", "none");
//         }
//         if ($(this).hasClass('actus')) {
//             $(".actus_container").css("display", "block");
//             $(".evenements_container, .facebook_container, .derniers_jours_container ").css("display", "none");
//         }
//         if ($(this).hasClass('agenda')) {
//             $(".evenements_container").css("display", "block");

//             $(".actus_container, .facebook_container, .derniers_jours_container ").css("display", "none");
//         }
//     }
// });

if ($(".toggle .toggle-title").hasClass('active')) {
    $(".toggle .toggle-title.active").closest('.toggle').find('.toggle-inner').show();
}

$(".toggle .toggle-title").click(function () {
    if ($(this).hasClass('active')) {
        $(this).removeClass("active").closest('.toggle').find('.toggle-inner').slideUp(200);
    } else {
        $(this).addClass("active").closest('.toggle').find('.toggle-inner').slideDown(200);
    }
});


if ($(".filter-toggle .filter-toggle-title").hasClass('active')) {
    $(".filter-toggle .filter-toggle-title.active").closest('.filter-toggle').find('.filter-toggle-inner').show();
}

$(".filter-toggle .filter-toggle-title").click(function () {
    if ($(this).hasClass('active')) {
        $(this).removeClass("active").closest('.filter-toggle').find('.filter-toggle-inner').slideUp(200);
    } else {
        $(this).addClass("active").closest('.filter-toggle').find('.filter-toggle-inner').slideDown(200);
    }
});

$('.exportExcel').click(function () {
    var newThis = $(this).next('.tableauMenu');
    var header = newThis.prev().prev('h2').text();

    var firstLigne = [];
    var secondLigne = [];
    var thirdLigne = [];
    var fourthLigne = [];
    var fifthLigne = [];
    var sixthLigne = [];
    var seventhLigne = [];
    newThis.find(".caseUn").each(function (index) {
        firstLigne.push($(this).text().trim());
    });
    newThis.find(".caseDeux").each(function (index) {
        secondLigne.push($(this).text().trim());
    });
    newThis.find(".caseTrois").each(function (index) {
        thirdLigne.push($(this).text().trim());
    });
    newThis.find(".caseQuatre").each(function (index) {
        fourthLigne.push($(this).text().trim());
    });
    newThis.find(".caseCinq").each(function (index) {
        fifthLigne.push($(this).text().trim());
    });

    if (!newThis.hasClass('creche')) {
        newThis.find(".caseSix").each(function (index) {
            sixthLigne.push($(this).text().trim());
        });
    }
    if (!newThis.hasClass('creche') && !newThis.hasClass('senior')) {
        newThis.find(".caseSept").each(function (index) {
            seventhLigne.push($(this).text().trim());
        });
    }

    var wb = XLSX.utils.book_new();

    wb.SheetNames.push("Test Sheet");
    var ws_data = [firstLigne, secondLigne, thirdLigne, fourthLigne, fifthLigne, sixthLigne, seventhLigne];

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets["Test Sheet"] = ws;
    var wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'binary'
    });

    function s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }

    saveAs(new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    }), header + '.xlsx');
});

$('.tableauMenu').click(function () {
    var doc = new jsPDF('l', 'mm', 'a4');
    var header = $(this).prev().prev('h2').text();
    if (header === '') {
        var header = $(this).prev('h2').text();
    }
    var thisEl = $(this);

    createTableMenu(doc, header, thisEl);
    doc.save(header + '.pdf');
});

$('.cantineMoisActuel').click(function (e) {
    e.preventDefault();

    var n = new Date().getMonth();
    const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const monthSlugs = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
    var className = '.tableauMenu-' + monthSlugs[n];
    var fileName = monthNames[n];
    var doc = new jsPDF('l', 'mm', 'a4');

    $(className).each(function () {
        var thisEl = $(this);
        var header = $(this).prev().prev('h2').text();
        if (header === '') {
            var header = $(this).prev('h2').text();
        }

        createTableMenu(doc, header, thisEl);
        doc.addPage();
    });

    doc.save(fileName + '.pdf');
});

$('.cantineMoisSuivant').click(function (e) {
    e.preventDefault();
//    
//    var fileName;
//    var className;
    var n = new Date().getMonth();
    const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    const monthSlugs = ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"];
    if (n == 11) {
        var className = '.tableauMenu-' + monthSlugs[0];
        var fileName = monthNames[0];
    } else {
        var className = '.tableauMenu-' + monthSlugs[n + 1];
        var fileName = monthNames[n + 1];
    }


    var doc = new jsPDF('l', 'mm', 'a4');

    $(className).each(function () {
        var thisEl = $(this);
        var header = thisEl.prev('h2').text();

        createTableMenu(doc, header, thisEl);
        doc.addPage();
    });

    doc.save(fileName + '.pdf');
});

if ($(".geoContainer").length) {
    var geolocButton = $(".geoContainer");

    $(".geoContainer").on("click", getLocation);


}


function createTableMenu(doc, header, thisEl) {
    var columns = [{
        title: " ",
        dataKey: "plat"
    }];

    thisEl.children('.tableauMenuJour').each(function (index, value) {
        var columnsEl = {};

        columnsEl['title'] = $(value).find('.caseUn').text().trim();
        columnsEl['dataKey'] = index;
        columns.push(columnsEl);
    });

    var rows = [];
    var currentTab = thisEl;
    var rowsConstruct = ['caseDeux', 'caseTrois', 'caseQuatre', 'caseCinq', 'caseSix', 'caseSept'];

    rowsConstruct.forEach(function (element) {
        var rowHorsDoeuvre = {};

        currentTab.find('.' + element).each(function (index, value, key) {
            if (index == 0) {
                rowHorsDoeuvre['plat'] = $(value).text().trim();
            } else {
                rowHorsDoeuvre[index - 1] = $(value).text().trim();
            }
        });

        if (!(currentTab.hasClass('creche') && element == "caseSix") && !(currentTab.hasClass('creche') && element == "caseSept") && !(currentTab.hasClass('senior') && element == "caseSept")) {
            rows.push(rowHorsDoeuvre);
        }
    });

    doc.autoTable(columns, rows, {
        theme: 'plain',
        styles: {
            cellPadding: 5,
            fontSize: 10,
            lineColor: 20,
            lineWidth: 0.25,
            fontStyle: 'normal',
            overflow: 'linebreak',
            textColor: 20,
            halign: 'center',
            valign: 'middle',
            columnWidth: 45,
        },
        headerStyles: { fillColor: [261, 30, 91], textColor: [255, 255, 255], fontStyle: 'bold', },
        columnStyles: {"plat": {halign: 'center', textColor: [261, 30, 91], fontStyle: 'bold',}},
        margin: {
            top: 40
        },
        addPageContent: function (data) {
            doc.text(header, 40, 30);
        }
    });
}

$('.bigmenu_ville li').click(function () {
    if($('.bigmenu_ville li').hasClass('.active')) {
        $('.bigmenu_ville li.active').removeClass('.active');
    }
    
    $(this).toggleClass('active');
});


function appendSvg(idName) {
    $('.meteoIcon').append(idName);
}

$.getJSON('https://api.openweathermap.org/data/2.5/weather?id=6456448&appid=d8718c4870179ba0ce7c41e3dfdd1726', function(dataOWM) {     
    var weatherId = dataOWM.weather[0].id;
    var weatherTemp = dataOWM.main.temp;
    weatherTemp = weatherTemp - 273.15;
    $('.temperature').append(Math.round(weatherTemp));
    switch (weatherId) {
        case 800:
            appendSvg(sun);
            break;
        case 801:
            appendSvg(cloudSun);
            break;
        case 801:
            appendSvg(cloud);
            break;
        case 803: case 804:
            appendSvg(cloudFill);
            break;
        case 500: 
            appendSvg(cloudDrizzleSun);
            break;
        case 501:
            appendSvg(cloudDrizzleSun);
            break;
        case 502:
            appendSvg(cloudDrizzle);
            break;
        case 503:
            appendSvg(cloudDrizzleSunAlt);
            break;
        case 504:
            appendSvg(cloudDrizzleAlt);
            break;
        case 511:
            appendSvg(cloudRainSun);
            break;
        case 520:
            appendSvg(cloudRain);
            break;
        case 521:
            appendSvg(cloudSunRainAlt);
            break;
        case 522: case 531: case 300: case 301: case 302: case 310: case 311: case 312: case 313: case 314: case 321:
            appendSvg(cloudRainAlt);
            break;
        case 600:
            appendSvg(cloudSnowSun);
            break;
        case 601:
            appendSvg(cloudSnow);
            break;
        case 602:
            appendSvg(cloudSnowSunAlt);
            break;
        case 611: case 612: case 615: case 616: case 620:
            appendSvg(cloudSnow);

            break;
        case 621: case 622:
            appendSvg(cloudSnowAlt);
            break;
        case 701:
            appendSvg(cloudFogSunAlt);
            break;
        case 711: case 721:
            appendSvg(cloudFogAlt);
            break;
        case 731:
            appendSvg(cloudFogSun);
            break;
        case 741:
            appendSvg(cloudFog);
            break;
        case 900:
            appendSvg(tornado);
            break;
        case 901: case 902: case 905:
            appendSvg(wind);
            break;
        case 906:
            appendSvg(cloudHailAlt);
            break;
        case 200: case 201: case 202: case 210: case 211: case 212: case 221: case 230: case 231: case 232:
            appendSvg(cloudLightning);
            break;
    }
});

// $.getJSON('https://api.waqi.info/feed/@4591/?token=1f3f4bd260b5067cd3a4397ae81ac18a1e03f246', function (data) {
// //        console.log(data);

//     if (data.data.aqi >= 0 && data.data.aqi <= 50) {
//         var classNameAqi = 'aqiBon';
//     } else if (data.data.aqi >= 51 && data.data.aqi <= 100) {
//         var classNameAqi = 'aqiMoyen';
//     } else if (data.data.aqi >= 101 && data.data.aqi <= 150) {
//         var classNameAqi = 'aqiMauvais';
//     }

//     //        $(".airQuality").addClass(classNameAqi);
//     $(".air").addClass(classNameAqi);
// });


function clearFormParameters(){
    var myForm = document.getElementById('annuairesFilters');
    var allInputs = myForm.getElementsByTagName('input');
    var input, i;

    for(i = 0; input = allInputs[i]; i++) {
        if(input.getAttribute('name') && !input.value) {
            input.setAttribute('name', '');
        }
    }
}

//localisation bouton formulaire signalement
function getLocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
    } else {
        geolocButton.innerHTML = "Geolocation is not supported by this browser.";
    }
}

function showPosition(position) {
    $(".geoContainer").val(position.coords.latitude + "," + position.coords.longitude);
}

if (/MSIE 10/i.test(navigator.userAgent)) {
   // This is internet explorer 10
   $('section.a_la_une .articles article.single img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : t.parent().css('height'),
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });
   $('.actus_container .articles article.single img, .grande_image img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : t.parent().css('height'),
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });
   $('.evenements_container .articles article.single img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : t.parent().css('height'),
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });
   $('.bigmenu_actus .derniers_jours_container .cff-item .img img, .bigmenu_actus .derniers_jours_container .fb_post_single .img img, .bigmenu_actus .facebook_container .cff-item .img img, .bigmenu_actus .facebook_container .fb_post_single .img img, .bigmenu_actus .derniers_jours_container .articles article.single img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : '200px',
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });
}


if (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent)) {
    // This is internet explorer 9 or 11
    $('section.a_la_une .articles article.single img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : t.parent().css('height'),
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });
     $('.actus_container .articles article.single img, .grande_image img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : t.parent().css('height'),
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });
   $('.evenements_container .articles article.single img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : t.parent().css('height'),
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });
   $('.bigmenu_actus .derniers_jours_container .cff-item .img img, .bigmenu_actus .derniers_jours_container .fb_post_single .img img, .bigmenu_actus .facebook_container .cff-item .img img, .bigmenu_actus .facebook_container .fb_post_single .img img, .bigmenu_actus .derniers_jours_container .articles article.single img').each(function(){
        var t = jQuery(this),
            s = 'url(' + t.attr('src') + ')',
            p = t.parent(),
            d = $('<div></div>');

        p.append(d);
        d.css({
            'height'                : '200px',
            'background-size'       : 'cover',
            'background-repeat'     : 'no-repeat',
            'background-position'   : '50% 50%',
            'background-image'      : s
        });
        t.hide();
    });

}